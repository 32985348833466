
import React from "react";
import UserDetail from "./UserDetail";
import UserId from "./UserId";

interface UserInfoProps {
  author: string;
  organisation: string;
  userFunction: string;
  userId: string;
}

const UserInfo: React.FC<UserInfoProps> = ({
  author,
  organisation,
  userFunction,
  userId,
}) => {
  return (
    <section className="flex flex-col px-4 py-3.5 font-medium leading-8 rounded-2xl bg-neutral-100 max-w-[228px] items-start justify-start">
      <UserDetail
        author={author}
        organisation={organisation}
        userFunction={userFunction}
      />
      <UserId userId={userId} />
    </section>
  );
};

export default UserInfo;
